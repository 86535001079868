import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useCallback, useRef, useState, useContext } from 'react';

import { SiteContext } from '../../context';
import { getPathForId } from '../../helpers/sitemap/getPathForId';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { TopNav } from './TopNav';

const MobileNav = dynamic(
  () => import(/* webpackChunkName: "MobileNav" */ './MobileNav'),
  {
    suspense: true,
  },
);

export default function Nav() {
  const router = useRouter();
  const scrollDirection = useScrollDirection();
  const scrollPosition = useScrollPosition();
  const showNav = scrollDirection === 'up' || scrollPosition !== 'middle';
  const { config, sitemap, pageNavigation } = useContext(SiteContext);

  const navRef = useRef(null);
  const [spacerHeight, setSpacerHeight] = useState(73);

  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  let menu = config.navigation?.menu;
  const quicklinks = config.navigation?.quicklinks;

  const cta = pageNavigation?.cta || config.navigation?.cta;
  const contact = [];
  if (config.contacts?.phone)
    contact.push({
      label: config.contacts.phone,
      href: `tel:${config.contacts.phone}`,
    });
  if (config.contacts?.email)
    contact.push({
      label: config.contacts.email,
      href: `mailto:${config.contacts.email}`,
    });
  if (config.contacts?.generalEmail)
    contact.push({
      label: config.contacts.generalEmail,
      href: `mailto:${config.contacts.generalEmail}`,
    });

  // set active state
  menu = menu?.map((item) => {
    const href = getPathForId(item.internal?._ref, sitemap) || item.external;

    return {
      ...item,
      current:
        router.asPath === '/'
          ? href === '/'
          : router.asPath.startsWith(href) && href !== '/',
      children: item.children?.map((subitem) => {
        const href =
          getPathForId(subitem.internal?._ref, sitemap) || subitem.external;
        return {
          ...subitem,
          current: router.asPath === href,
        };
      }),
    };
  });

  /**
   * Measure nav and create a spacer of equal height
   */

  const measureNav = useCallback((node) => {
    if (node) {
      const navHeight = node?.getBoundingClientRect().height;
      setSpacerHeight(navHeight);
      navRef.current = node;
    }
  }, []);

  useEffect(() => {
    function onResize() {
      measureNav(navRef.current);
    }

    window.addEventListener('resize', onResize, { passive: true });
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const onHamburgerClick = () => {
    setMobileNavIsOpen(true);
  };

  useEffect(() => {
    function onRouteChange() {
      setMobileNavIsOpen(false);
    }

    router.events.on('routeChangeStart', onRouteChange);
    () => router.events.off('routeChangeStart', onRouteChange);
  }, []);

  return (
    <div>
      <div style={{ height: spacerHeight }} className="invisible" />

      <MobileNav
        menu={menu}
        cta={cta}
        contact={contact}
        quicklinks={quicklinks}
        open={mobileNavIsOpen}
        onOpenChange={setMobileNavIsOpen}
      />

      <TopNav
        menu={menu}
        cta={cta}
        contact={contact}
        quicklinks={quicklinks}
        onHamburgerClick={onHamburgerClick}
        showNav={showNav}
        ref={measureNav}
        navHeight={spacerHeight}
      />
    </div>
  );
}
