import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu';
import cx from 'classnames';
import React, { useRef } from 'react';

import { InternalExternalLink } from '../Link/InternalExternalLink';
import { IconLoader } from '../images/IconLoader';
import { Width } from '../module/Width';

const TopNavComponent = (
  { menu, cta, contact, quicklinks, showNav = true, navHeight, onHamburgerClick },
  navRef,
) => {
  const wrapperRef = useRef(null);

  const wrapperStyle = showNav
    ? null
    : {
        transform: `translateY(-${navHeight}px)`,
      };

  return (
    <div ref={wrapperRef} tabIndex={-1}>
      <div
        className={cx(
          'fixed z-[999] top-0 left-0 right-0 w-full transform transition-transform duration-500 shadow',
        )}
        style={wrapperStyle}
      >
        <RadixNavigationMenu.Root role="navigation">
          {/* Banner */}
          <div className="bg-pastel-paper shadow-xs" ref={navRef}>
            <Width>
              <div className="py-3 flex w-full items-center">
                {/* logo */}
                <InternalExternalLink
                  href="/"
                  className="mr-auto focus:outline outline-1 outline-offset-4 outline-blue-navy/50 text-black"
                >
                  <span className="hidden lg:block">
                    <IconLoader
                      src="/logo-desktop.svg"
                      className="w-[130px] h-[49px]"
                    />
                  </span>
                  <span className="block lg:hidden">
                    <IconLoader
                      className="w-[94px] h-[36px]"
                      src="/logo-mobile.svg"
                    />
                  </span>
                </InternalExternalLink>

                <div className="flex nav:divide-x divide-blue-navy/[.2] gap-5 items-center">
                  {/* contact */}
                  {Boolean(contact?.length) && (
                    <RadixNavigationMenu.List className="hidden nav:flex flex-row gap-4 text-xs">
                      {contact?.map(({ label, href }) => (
                        <RadixNavigationMenu.Item
                          key={label}
                          className="hidden md:block"
                        >
                          <InternalExternalLink
                            href={href}
                            className="hover:underline focus:underline underline-offset-4 text-blue-navy"
                          >
                            {label}
                          </InternalExternalLink>
                        </RadixNavigationMenu.Item>
                      ))}
                    </RadixNavigationMenu.List>
                  )}

                  {Boolean(quicklinks?.length) && (
                    <RadixNavigationMenu.List className="flex flex-row gap-4 text-sm xsnav:pl-5">
                      {quicklinks?.map(({ label, internal, external, params }) => {
                        const isScopeSwitch =
                          label.toLowerCase().indexOf('corporate') > -1 ||
                          label.toLowerCase().indexOf('customer') > -1;

                        return (
                          <RadixNavigationMenu.Item key={label}>
                            <InternalExternalLink
                              id={internal?._ref}
                              href={external}
                              params={params}
                              className={cx(
                                'hover:underline focus:underline underline-offset-4 text-blue-navy',
                                {
                                  ['bg-pastel-sand px-2 py-1 rounded-sm transition-colors hover:bg-gray-china']:
                                    isScopeSwitch,
                                  ['hidden xsnav:block']: !isScopeSwitch,
                                },
                              )}
                            >
                              {label}
                            </InternalExternalLink>
                          </RadixNavigationMenu.Item>
                        );
                      })}
                    </RadixNavigationMenu.List>
                  )}

                  <div
                    className={cx('nav:hidden', {
                      ['hidden']: !Boolean(menu?.length) && !Boolean(cta?.length),
                    })}
                  >
                    <button
                      type="button"
                      onClick={onHamburgerClick}
                      aria-label="Open navigation"
                      className="bg-blue-navy p-2 rounded-sm -mr-4"
                    >
                      <IconLoader
                        className="w-6 h-6 text-white"
                        src="/icons/menu.svg"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </Width>
          </div>

          {/* Menu */}
          <div
            className={cx(
              'backdrop-contrast-200 backdrop-blur-[3px] hidden md:block',
              {
                ['bg-black/70']: showNav,
                ['bg-black/80']: !showNav,
              },
            )}
          >
            <Width>
              <div
                className={cx('flex items-center transition-[padding]', {
                  ['py-3 xl:py-4']: showNav,
                  ['py-2']: !showNav,
                })}
              >
                {/* Links */}
                <div className="">
                  {Boolean(menu?.length) && (
                    <RadixNavigationMenu.List className="flex gap-6 xl:gap-8 ml-auto items-center">
                      {menu?.map(
                        ({
                          label,
                          internal,
                          external,
                          params,
                          children,
                          current,
                        }) => {
                          const Element = Boolean(children?.length)
                            ? RadixNavigationMenu.Trigger
                            : 'div';

                          return (
                            <RadixNavigationMenu.Item
                              key={label}
                              className="relative group"
                            >
                              {/* This renders <button><a></button> for links with subnav, not pretty - not sure how to fix yet */}
                              <Element className="flex items-center gap-1.5">
                                <RadixNavigationMenu.Link asChild>
                                  <InternalExternalLink
                                    id={internal?._ref}
                                    href={external}
                                    params={params}
                                    className={cx(
                                      'text-white font-regular lg:text-sm xl:text-base 2xl:text-[19px] hover:underline focus:underline underline-offset-4',
                                      {
                                        ['underline decoration-white/40']: current,
                                      },
                                    )}
                                  >
                                    {label}
                                  </InternalExternalLink>
                                </RadixNavigationMenu.Link>

                                {children && (
                                  <IconLoader
                                    className="
                                    w-[10px] h-[10px] text-white rotate-90 opacity-70 translate-y-0.5 
                                    group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity"
                                    src="/icons/chevron.svg"
                                  />
                                )}
                              </Element>

                              {Boolean(children?.length) && (
                                <RadixNavigationMenu.Content
                                  className={cx(
                                    'w-64',
                                    'absolute bottom-0',
                                    'translate-y-full',
                                  )}
                                >
                                  <RadixNavigationMenu.List
                                    className={cx(
                                      'translate-y-4 divide-y divide-black/[.1] relative',
                                      'shadow-md',
                                    )}
                                  >
                                    <span className="block w-0 h-0 border-t-0 border-l-8 border-r-8 border-b-8 border-transparent border-b-pastel-paper absolute -top-2 left-2 translate-y-px" />
                                    {children?.map(
                                      ({
                                        label,
                                        current,
                                        internal,
                                        params,
                                        external,
                                      }) => (
                                        <RadixNavigationMenu.Item
                                          className="-mt-px bg-pastel-paper p-3 rounded-sm"
                                          key={label}
                                        >
                                          <RadixNavigationMenu.Link asChild>
                                            <InternalExternalLink
                                              id={internal?._ref}
                                              href={external}
                                              params={params}
                                              className={cx(
                                                'hover:underline focus:underline flex items-start underline-offset-4 gap-2 text-gray-deep',
                                                {
                                                  ['underline  decoration-black/20']:
                                                    current,
                                                },
                                              )}
                                            >
                                              <span className="w-5 h-5 p-1 grid place-items-center rounded-full bg-pastel-sand translate-y-0.5 flex-shrink-0">
                                                <IconLoader
                                                  className="w-2 h-2"
                                                  src="/icons/chevron.svg"
                                                />
                                              </span>
                                              <span>{label}</span>
                                            </InternalExternalLink>
                                          </RadixNavigationMenu.Link>
                                        </RadixNavigationMenu.Item>
                                      ),
                                    )}
                                  </RadixNavigationMenu.List>
                                </RadixNavigationMenu.Content>
                              )}
                            </RadixNavigationMenu.Item>
                          );
                        },
                      )}
                    </RadixNavigationMenu.List>
                  )}
                </div>

                {/* CTA */}
                <div className="ml-auto hidden lg:block">
                  <RadixNavigationMenu.List className="flex gap-2 xl:gap-4 items-center ml-auto lg:ml-8">
                    {Boolean(cta?.length) &&
                      cta?.map(({ label, internal, external, params }) => (
                        <RadixNavigationMenu.Item
                          key={label}
                          className="hidden md:block"
                        >
                          <InternalExternalLink
                            id={internal?._ref}
                            href={external}
                            params={params}
                            className={cx(
                              'bg-black bg-opacity-20 border border-white border-opacity-20 text-white',
                              'rounded-sm text-sm lg:text-[17px]',
                              'hover:underline focus:underline underline-offset-4 hover:bg-opacity-30 transition-colors focus:bg-opacity-30',
                              'flex gap-2 items-center',
                              'transition-[padding]',
                              {
                                ['py-2 pr-3 pl-4']: showNav,
                                ['py-1 pr-2 pl-3 ']: !showNav,
                              },
                            )}
                          >
                            {label}
                            <IconLoader
                              className="w-3 h-3 text-white"
                              src="/icons/chevron.svg"
                            />
                          </InternalExternalLink>
                        </RadixNavigationMenu.Item>
                      ))}
                  </RadixNavigationMenu.List>
                </div>
              </div>
            </Width>
          </div>
        </RadixNavigationMenu.Root>
      </div>
    </div>
  );
};

export const TopNav = React.forwardRef(TopNavComponent);
