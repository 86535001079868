import cx from 'classnames';
import React from 'react';

export const Width = ({ children, width, className }) => {
  return (
    <div
      className={cx('mx-auto', className, {
        [paddingClasses]: width !== 'full',
      })}
    >
      {children}
    </div>
  );
};

export const WidthMemo = React.memo(Width);

export const paddingClasses = 'px-[28px] lg:px-[78px] 3xl:px-[178px] 4xl:px-[15vw]';
export const breakoutClasses = '-mx-[28px] lg:-mx-[78px] 3xl:-mx-0 4xl:mx-0';
